<template>
  <div>
    <v-form ref="validForm" lazy-validation>
      <v-container cols="12" class="pt-0 mt-0">
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-card :loading="solicitationLoad" :disabled="solicitationLoad" outlined>
              <v-card-title>
                <h3>
                  <v-icon class="mr-3" color="primary">
                    mdi-format-list-checks
                  </v-icon>
                  Editar tipo de solicitação
                </h3>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form v-if="isLoaded" v-model="valid" ref="form" lazy-validation>
                    <v-row>
                      <v-col xs="12" md="4" lg="4" class="pl-0 py-0">
                        <v-text-field outlined dense lazy label="Nome" :rules="defaultRules"
                          v-model="selectedSolicitationTypeDetail.display_name"></v-text-field>
                      </v-col>
                      <v-col xs="12" md="3" lg="2" class="py-0">
                        <v-text-field outlined dense label="Criado em" disabled
                          v-model="selectedSolicitationTypeDetail.created"></v-text-field>
                      </v-col>
                      <v-col xs="12" md="3" lg="2" class="pr-0 py-0">
                        <v-text-field outlined dense label="Atualizado em" disabled
                          v-model="selectedSolicitationTypeDetail.updated"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="10" lg="8" xs="12" class="pa-0">
                        <v-textarea outlined dense label="Descrição" rows="3"
                          v-model="selectedSolicitationTypeDetail.description" auto-grow></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col lg="4" md="5" cols="12" class="pl-0 py-0">
                        <label>SLA de Primeira Interação</label>
                        <v-row>
                          <v-col xs="12" md="4" cols="4" class="py-0">
                            <v-text-field outlined dense label="Dias" type="number" min="0" lazy
                              v-model="selectedSolicitationType.sla_validate_days"></v-text-field>
                          </v-col>
                          <v-col xs="12" md="4" cols="4" class="py-0">
                            <v-text-field outlined dense label="Horas" type="number" min="0" max="23" v-model="
                              selectedSolicitationType.sla_validate_hours
                            "></v-text-field>
                          </v-col>
                          <v-col xs="12" md="4" cols="4" class="py-0">
                            <v-text-field outlined dense label="Minutos" type="number" min="0" max="60" v-model="
                              selectedSolicitationType.sla_validate_minutes
                            "></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col xs="12" lg="4" md="5" cols="12" class="pa-0">
                        <label>SLA de Finalização</label>
                        <v-row>
                          <v-col xs="12" md="4" cols="4" class="py-0">
                            <v-text-field outlined dense label="Dias" type="number" min="0"
                              v-model="selectedSolicitationType.sla_finish_days"></v-text-field>
                          </v-col>
                          <v-col xs="12" md="4" cols="4" class="py-0">
                            <v-text-field outlined dense label="Horas" type="number" min="0" max="23"
                              v-model="selectedSolicitationType.sla_finish_hours"></v-text-field>
                          </v-col>
                          <v-col xs="12" md="4" cols="4" class="py-0">
                            <v-text-field outlined dense label="Minutos" type="number" min="0" max="60" v-model="
                              selectedSolicitationType.sla_finish_minutes
                            "></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="10" lg="8" xs="12" class="pa-0">
                        <v-autocomplete :items="providers" item-text="name" item-value="id" :rules="defaultRules" @change="changeProvider" outlined label="Fornecedor"
                          clearable dense v-model="selectedSolicitationType.provider_id">
                          <template v-slot:selection="data">
                            {{ data.item.name }} - {{ data.item.email }}
                          </template>
                          <template v-slot:item="data">
                            <template v-if="typeof data.item !== 'object'">
                              <v-list-item-content v-text="data.item"></v-list-item-content>
                            </template>
                            <template v-else>
                              <v-list-item-content>
                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row>
                    <v-col md="10" lg="8" xs="12" class="pa-0">
                      <solicitationType-tags-component
                        v-if="isLoaded"
                        :company_id="companyId"
                        :tags_selected="selectedTags"
                        @save="saveTags"
                      >
                      </solicitationType-tags-component>
                      <v-skeleton-loader v-else type="table-heading, article, actions"></v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="10" lg="8" xs="12" class="pa-0">
                      <v-autocomplete v-if="isLoaded" dense outlined required clearable label="Automação"
                        v-model="automation_id" :items="automations" item-text="display_name" item-value="id"
                        :loading="!isLoaded">
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.display_name" />
                            <v-list-item-subtitle style="font-size: 11px" v-html="data.item.description" />
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="10" lg="8" xs="12" class="pa-0">
                      <solicitationType-fields-component type="edit" v-if="isLoaded">
                      </solicitationType-fields-component>
                      <v-skeleton-loader v-else type="table-heading, article, actions"></v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <v-row v-if="isLoaded">
                    <v-col md="10" lg="8" xs="12" class="pa-0">
                      <v-sheet
                        class="mx-auto mt-6 pa-0"
                        elevation="6"
                      >
                        <v-col cols="12" >
                          <EmailApproverComponent
                            :index="0"
                            :selectedId="$route.params.id"
                            @changeSubject="changeSubject($event)"
                            @changeMessage="changeMessage($event)"
                            @changeTo="changeTo($event)"
                            @changeCc="changeCc($event)"
                            @disableButton="disableButton($event)"
                            :defaultEmail="defaultToEmail"
                            :solicitationType="true"
                            :toEmail="selectedSolicitationType.to_email"
                            :ccEmail="selectedSolicitationType.cc_email"
                            :messageEmail="selectedSolicitationType.message_email"
                            :subjectEmail="selectedSolicitationType.subject_email"
                          />
                        </v-col>
                      </v-sheet>
                    </v-col>
                  </v-row>
                  <v-col md="10" lg="8" xs="12" class="pa-0 py-5">
                    <v-row justify="end">
                      <v-btn outlined color="primary" :loading="isLoaded == false" :to="{ name: 'solicitation-type-list' }"
                        exact class="ma-2">Voltar
                      </v-btn>
                      <v-btn class="ma-2" color="primary" :loading="btn_loading" :disabled="btnDisable"
                        @click="updateSolicitationTypeItem">Salvar
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import SolicitationTypeTagsComponent from "@/components/solicitationType/SolicitationTypeTagsComponent";
import SolicitationTypeFieldsComponent from "@/components/solicitationType/SolicitationTypeFieldsComponent";
import { mapActions, mapState } from "vuex";
import EmailApproverComponent from "../../components/solicitationType/EmailApproverComponent.vue";

export default {
  components: {
    EmailApproverComponent,
    SolicitationTypeTagsComponent,
    SolicitationTypeFieldsComponent
  },
  data() {
    return {
      valid: true,
      nameRules: [v => !!v || "Nome obrigatório"],
      btn_loading: false,
      btnDisable: true,
      providers: [],
      selectedSolicitationType: "",
      selectedSolicitationTypeDetail: "",
      solicitationLoad: true,
      defaultRules: [v => !!v || "Campo obrigatório"],
      companyId: "",
      providerId: "",
      subject_email: "",
      message_email: "",
      defaultToEmail: "",
      to_email: "",
      cc_email: [],
      isLoaded: false,
      solicitationTypeId: "",
      automations: [],
      automation_id: "",
      tags: [],
      selectedTags: [],
    };
  },
  async mounted() {
    await this.loadProvider().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    await this.loadSelectedSolicitationType({
      id: this.$route.params.id
    }).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    await this.loadAutomation().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    // this.loadFieldTypes();
    this.changeSolicitationTypeNewOrEdit("edit");

    this.providers = this.provider_list.data;
    this.selectedSolicitationType = this.selected_solicitation_type.data;
    this.selectedSolicitationTypeDetail = this.selected_solicitation_type.data;
    this.automation_id = this.selected_solicitation_type.data.automation_id;
    this.companyId = this.selectedSolicitationType.company_id;
    this.subject_email = this.selectedSolicitationType.subject_email;
    this.message_email = this.selectedSolicitationType.message_email;
    this.to_email = this.selectedSolicitationType.to_email;
    this.cc_email = this.selectedSolicitationType.cc_email;
    this.solicitationTypeId = this.$route.params.id;
    this.solicitationLoad = false;
    this.isLoaded = true;
    this.btnDisable = false;
    this.automations = this.automation_list.data;
    this.providerId = this.selectedSolicitationType.provider_id;
    this.selectedTags = this.selectedSolicitationType.tags;
    this.setDefaultToEmail();
  },
  computed: {
    ...mapState("solicitationType", [
      "selected_solicitation_type",
      "solicitationType_update",
      "fields"
    ]),
    ...mapState("automation", ["automation_list"]),
    ...mapState("categories", ["categories", "categoriesToSave"]),
    ...mapState("provider", ["provider_list"]),
    ...mapState("rules", ["rules"]),
    ...mapState("approvalRules", ["solicitationTypeApprovalRules"])
  },
  methods: {
    ...mapActions("rules", ["loadRules"]),
    ...mapActions("solicitationType", [
      "loadSelectedSolicitationType",
      "changeSolicitationTypeNewOrEdit",
      "loadFieldTypes"
    ]),
    ...mapActions("automation", ["loadAutomation"]),
    ...mapActions("provider", ["loadProvider"]),
    ...mapActions("solicitationType", ["updateSolicitationType"]),
    ...mapActions("approvalRules", ["loadApprovalRules"]),

    saveTags (value) {
      this.tags = value;
    },

    async updateSolicitationTypeItem() {
      if (this.$refs.validForm.validate()) {
        this.btn_loading = true;
        const body = {
          display_name: this.selectedSolicitationTypeDetail.display_name,
          description: this.selectedSolicitationTypeDetail.description,
          sla_finish_days: this.selectedSolicitationType.sla_finish_days,
          sla_finish_hours: this.selectedSolicitationType.sla_finish_hours,
          sla_finish_minutes: this.selectedSolicitationType.sla_finish_minutes,
          sla_validate_days: this.selectedSolicitationType.sla_validate_days,
          sla_validate_hours: this.selectedSolicitationType.sla_validate_hours,
          sla_validate_minutes: this.selectedSolicitationType.sla_validate_minutes,
          provider_id: this.selectedSolicitationType.provider_id,
          subject_email: this.selectedSolicitationType.subject_email,
          message_email: this.selectedSolicitationType.message_email,
          to_email: this.selectedSolicitationType.to_email,
          cc_email: this.selectedSolicitationType.cc_email,
          fields: this.fields,
          categories: this.categoriesToSave,
          solicitation_type: this.selectedSolicitationTypeDetail.id,
          automation_id: this.automation_id || null,
          tags: this.tags
        };

        await this.updateSolicitationType(body).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
        if (!this.solicitationType_update.success) {
          this.$swal("Oops ...", this.solicitationType_update.message, "warning");
          this.btn_loading = false;
        } else {
          this.btn_loading = false;
          this.$swal({
            icon: "success",
            title: this.solicitationType_update.message,
            showCancelButton: true,
            confirmButtonText: "Ok",
            cancelButtonText: "Listagem",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#445E93",
            showLoaderOnConfirm: true
          }).then(result => {
            if (!result.value) {
              this.$router.push({
                name: "solicitation-type-list"
              });
            }
          });
        }
        this.btn_loading = false;
      }
    },

    changeSubject(item) {
      this.selectedSolicitationType.subject_email = item.title;
    },

    changeMessage(item) {
      this.selectedSolicitationType.message_email = item.message;
    },

    changeTo(item) {
      this.selectedSolicitationType.to_email = item.to;
    },

    changeCc(item) {
      this.selectedSolicitationType.cc_email = item.cc;
      this.btnDisable = false;
    },

    disableButton(item) {
      this.btnDisable = item.value;
    },

    changeProvider(providerId) {
      const selectedProvider = this.providers.find(provider => provider.id === providerId);
      if (selectedProvider) {
        this.selectedSolicitationType.to_email = selectedProvider.email;
        this.defaultToEmail = selectedProvider.email;
      }
    },

    setDefaultToEmail() {
      if (this.providerId) {
        const selectedProvider = this.providers.find(provider => provider.id === this.providerId);
        if (selectedProvider) {
          this.defaultToEmail = selectedProvider.email;
        }
      }
    }
  }
};
</script>
